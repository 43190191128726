import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./core/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate {
  private userPermissions: Array<Record<string, any>> = [];

  constructor(private authService: AuthService, private router: Router) {
    this.loadPermissions();
  }

  getValue(key: string): number {
    const formattedKey = key.toUpperCase();
    const permission = this.userPermissions.find(
      (p) => p[formattedKey] !== undefined
    );
    return permission?.[formattedKey]?.view || 0;
  }

  private loadPermissions(): void {
    try {
      const storedPermissions = sessionStorage.getItem("permission");
      this.userPermissions = storedPermissions
        ? JSON.parse(storedPermissions)
        : [];
    } catch {
      this.userPermissions = [];
    }
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const requiredPermission = next.data.permission as string;
    if (this.getValue(requiredPermission)) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }
}
