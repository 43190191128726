import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError, observable, Observable, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { ToasterService } from "../admin/core/services/toaster.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string;
  constructor(private toaster: ToasterService) {}
  req: any;
  private baseUrl = environment.apiUrl;
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // try {
    this.token = sessionStorage.getItem("token");
    if (!this.token) {
      return next.handle(request);
    }
    if (this.token) {
      let apiUrl = `${this.baseUrl}/${request.url}`;

      // if (request.url === `http://192.168.100.17/amiscript.php`) {
      //   apiUrl = request.url;
      // }

      this.req = request.clone({
        headers: request.headers.set("Authorization", this.token),
        url: apiUrl,
      });

      const response = next.handle(this.req);

      return response.pipe(
        catchError((error: HttpErrorResponse) => {
          let err = error?.error;

          if (error?.status === 200 && error?.statusText === "OK") {
            return;
          }
          if (err?.meta) {
            this.toaster.error(error?.error?.meta?.message);
            return;
          }
          if (error?.message) {
            this.toaster.error(error?.message);
            return;
          }
          return throwError(() => null);
        })
      );
    }
  }
}
