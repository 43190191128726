import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { ActiveCallsComponent } from "./consultations/active-calls/active-calls.component";
import { PendingAndClosedCallsComponent } from "./consultations/pening-and-close-calls/pending-and-close.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { DoctorsPanelComponent } from "./doctors-panel/doctors-panel.component";
import { IncommingCallsComponent } from "./incomming-calls/incomming-calls.component";
import { ChangepasswordComponent } from "./setting/changepassword/changepassword.component";
import { EditprofileComponent } from "./setting/editprofile/editprofile.component";
import { UserLocationComponent } from "./user-management/components/user-location/user-location.component";
import { UserRightsComponent } from "./user-management/components/user-rights/user-rights.component";
import { UserTypesComponent } from "./user-management/components/user-types/user-types.component";
import { ViewAllUsersComponent } from "./user-management/components/view-all-users/view-all-users.component";
import { ReportsComponent } from "./user-management/components/reports/reports.component";

import { permissions } from "./core/enums/permission";
import { PermissionGuard } from "./permission.guard";

const routes: Routes = [
  {
    path: "",
    component: AdminComponent,
    children: [
      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        data: {
          permission: permissions.DASHBOARD,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "doctors-panel",
        component: DoctorsPanelComponent,
      },
      {
        path: "consultations",
        component: PendingAndClosedCallsComponent,
        data: {
          permission: permissions.CONSULTATIONS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "closed-calls",
        component: PendingAndClosedCallsComponent,
        data: {
          permission: permissions.CLOSED_CALLS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "active-calls",
        component: ActiveCallsComponent,
        data: {
          permission: permissions.ACTIVE_CALLS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "incomming-calls",
        component: IncommingCallsComponent,
        data: {
          permission: permissions.HELPLINE_PENDING_CALLS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "incomming-calls-closed",
        component: IncommingCallsComponent,
        data: {
          permission: permissions.HELPLINE_CLOSE_CALLS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "users",
        component: ViewAllUsersComponent,
        data: {
          permission: permissions.VIEW_ALL_USERS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "role-permissions",
        component: UserRightsComponent,
        data: {
          permission: permissions.USER_RIGHTS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "role-permissions/view/:id",
        component: UserRightsComponent,
        data: {
          permission: permissions.USER_RIGHTS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "role-permissions/edit/:id",
        component: UserRightsComponent,
        data: {
          permission: permissions.USER_RIGHTS,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "roles",
        component: UserTypesComponent,
        data: {
          permission: permissions.USER_TYPES,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "user-location",
        component: UserLocationComponent,
        data: {
          permission: permissions.USER_LOCATIONS,
        },
        canActivate: [PermissionGuard],
      },
      { path: "user-reports", component: ReportsComponent },
      {
        path: "edit-profile",
        component: EditprofileComponent,
        data: {
          permission: permissions.EDIT_PROFILE,
        },
        canActivate: [PermissionGuard],
      },
      {
        path: "change-password",
        component: ChangepasswordComponent,
        data: {
          permission: permissions.CHANGE_PASSWORD,
        },
        canActivate: [PermissionGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
