import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private userPermissions: Record<string, any>[] = [];

  constructor(private http: HttpService) {}

  login(user: any) {
    return this.http.post(`${environment.apiUrl}/auth/login`, user);
  }

  confirmPassword(
    oldPassword: number,
    newPassword: number,
    confirmPassword: number
  ) {
    let body = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    return this.http.put(`auth/change-password`, body);
  }
  async addUsers(data: any) {
    return await this.http.postRequest({
      url: `auth/signup`,
      body: data,
    });
  }
  async updateUser(id: string, body: {}) {
    return this.http.put(`admin/user/${id}`, body);
  }
}
