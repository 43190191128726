<form [formGroup]="form" class="container-fluid" action="">
  <!-- <div class="container"> -->
  <div class="row">
    <div class="col-6 heading">
      <p style="color: white; font-size: 1.3rem">Change Password</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6">
      <label class="form-label"> Old Password </label>
      <input
        formControlName="oldPassword"
        type="password"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Password"
        [class]="
          form.get('oldPassword') &&
          form.get('oldPassword').touched &&
          form.get('oldPassword').invalid
            ? 'is-invalid'
            : ''
        "
        [minlength]="validations.password.minLength"
        [maxlength]="validations.password.maxLength"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('oldPassword') &&
          form.get('oldPassword').touched &&
          form.get('oldPassword').errors
        "
      >
        <span *ngIf="form.get('oldPassword').getError('required')"
          >Required Field</span
        >
        <span *ngIf="form.get('oldPassword').getError('minlength')"
          >Password must be 8 characters long</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6">
      <label class="form-label"> New Password </label>
      <input
        formControlName="newPassword"
        type="password"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Password"
        [class]="
          form.get('newPassword') &&
          form.get('newPassword').touched &&
          form.get('newPassword').invalid
            ? 'is-invalid'
            : ''
        "
        [minlength]="validations.password.minLength"
        [maxlength]="validations.password.maxLength"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('newPassword') &&
          form.get('newPassword').touched &&
          form.get('newPassword').errors
        "
      >
        <span *ngIf="form.get('newPassword').getError('required')"
          >Required Field</span
        >
        <span *ngIf="form.get('newPassword').getError('minlength')"
          >Password must be 8 characters long</span
        >
        <span *ngIf="form.get('newPassword').getError('pattern')"
          >Password must contain at least one number, one lowercase letter, and
          one special character</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6">
      <label class="form-label"> Confirm Password </label>
      <input
        formControlName="confirm"
        type="password"
        class="form-control shadow-sm bg-body rounded bgcolorr"
        placeholder="Password"
        [class]="
          form.get('confirm') &&
          form.get('confirm').touched &&
          form.get('confirm').invalid
            ? 'is-invalid'
            : ''
        "
        [minlength]="validations.password.minLength"
        [maxlength]="validations.password.maxLength"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          form.get('confirm') &&
          form.get('confirm').touched &&
          form.get('confirm').errors
        "
      >
        <span *ngIf="form.get('confirm').getError('required')"
          >Required Field</span
        >
        <span *ngIf="form.get('confirm').getError('minlength')"
          >Password must be 8 characters long</span
        >
        <span
          *ngIf="
            form.get('confirm').getError('pattern') &&
            !form.get('confirm').getError('minlength')
          "
          >Password must contain at least one number, one lowercase letter, and
          one special character</span
        >
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-sm-6">
      <button class="btn btn-danger" (click)="submit()">Save</button>
    </div>
  </div>
  <!-- </div> -->
</form>
