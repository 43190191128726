import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";

const routes: Routes = [

  {
    path: "admin",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./admin/admin.module").then((file) => file.AdminModule),
  },
  {
    path: "auth",
    loadChildren: () =>
      import("./auth/auth.module").then((file) => file.AuthModule),
  },
  {
    path: "**",
    redirectTo: "admin",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
