import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { adminAppValidations } from "src/app/shared/interface";
import { AuthService } from "../../core/services/auth.service";
import { ToasterService } from "../../core/services/toaster.service";

@Component({
  selector: "app-changepassword",
  templateUrl: "./changepassword.component.html",
  styleUrls: ["./changepassword.component.scss"],
})
export class ChangepasswordComponent implements OnInit {
  validations = adminAppValidations;
  constructor(
    private http: AuthService,
    private formBuilder: FormBuilder,
    private toaster: ToasterService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }
  form: FormGroup;

  initForm() {
    this.form = this.formBuilder.group({
      oldPassword: [
        "",
        [
          Validators.required,
          Validators.minLength(this.validations.password.minLength),
        ],
      ],
      newPassword: [
        "",
        [
          Validators.required,
          Validators.minLength(this.validations.password.minLength),
          Validators.pattern(this.validations.password.numberRegex),
          Validators.pattern(this.validations.password.specialCharRegex),
          Validators.pattern(this.validations.password.uppercaseRegex),
        ],
      ],
      confirm: [
        "",
        [
          Validators.required,
          Validators.minLength(this.validations.password.minLength),
          Validators.pattern(this.validations.password.numberRegex),
          Validators.pattern(this.validations.password.specialCharRegex),
          Validators.pattern(this.validations.password.uppercaseRegex),
        ],
      ],
    });
  }

  async submit() {
    let valueField = this.form.value;
    if (valueField.newPassword === valueField.confirm) {
      try {
        await this.http.confirmPassword(
          valueField.oldPassword,
          valueField.newPassword,
          valueField.confirm
        );
        this.toaster.success("Password Updated Succesfully");
        this.initForm();
      } catch (err) {
        this.toaster.error(err.error.meta.message);
      }
    } else {
      this.toaster.error("New Password and Confirm Password do not Match");
    }
  }
}
